<template>
    <div class="page-wrapper page-home">
        <!-- Hero -->
        <header class="hero" v-if="contentIsLoaded">
            <div
                class="hero-slide"
                :class="'hero-slide' + (i + 1)"
                v-for="(item, i) in content.page.heroFolder"
                :key="i"
            >
                <img :src="item.image.url" :alt="item.image.titre" />
                <div class="content">
                    <h1 class="big-title title-down" :class="'color-' + (i + 1)">
                        <span v-html="item.titre"></span>
                    </h1>
                </div>
            </div>
        </header>

        <!-- Intro -->
        <section class="intro site-max-width" v-if="pageIsLoaded">
            <h2 class="big-title intro-title" data-inview="fadeInUp" data-delay="400">
                <span v-html="content.page.intro.titre"></span>
            </h2>
            <div class="bottom">
                <p class="regular-text" data-inview="fadeInUp" data-delay="500">
                    <span v-html="content.page.intro.texte"></span>
                </p>
                <router-link
                    :to="content.page.intro.boutonUrl[$route.meta.lang]"
                    class="site-btn"
                    data-inview="fadeInUp"
                    data-delay="600"
                >
                    <span class="special"
                        ><span v-html="content.page.intro.bouton.customText"></span
                    ></span>
                </router-link>
            </div>
        </section>

        <!-- Menu -->
        <section class="menu site-max-width" v-if="pageIsLoaded">
            <div class="left">
                <h2 class="big-title" data-inview="fadeInUp" data-delay="400">
                    <span v-html="content.page.split.titre"></span>
                </h2>
                <p class="regular-text" data-inview="fadeInUp" data-delay="600">
                    <span v-html="content.page.split.texte"></span>
                </p>
                <router-link
                    :to="content.page.split.boutonUrl"
                    class="site-btn"
                    data-inview="fadeInUp"
                    data-delay="200"
                >
                    <span class="special">{{ content.page.split.bouton.customText }}</span>
                </router-link>
            </div>
            <div class="right">
                <div class="imgs imgs-left">
                    <div class="scroller scroller-left">
                        <img :src="content.page.split.image1.url" :alt="content.page.split.image1.titre" />
                        <img :src="content.page.split.image2.url" :alt="content.page.split.image2.titre" />
                        <img :src="content.page.split.image3.url" :alt="content.page.split.image3.titre" />

                        <img :src="content.page.split.image1.url" :alt="content.page.split.image1.titre" />
                        <img :src="content.page.split.image2.url" :alt="content.page.split.image2.titre" />
                        <img :src="content.page.split.image3.url" :alt="content.page.split.image3.titre" />

                        <img :src="content.page.split.image1.url" :alt="content.page.split.image1.titre" />
                        <img :src="content.page.split.image2.url" :alt="content.page.split.image2.titre" />
                        <img :src="content.page.split.image3.url" :alt="content.page.split.image3.titre" />

                        <img :src="content.page.split.image1.url" :alt="content.page.split.image1.titre" />
                        <img :src="content.page.split.image2.url" :alt="content.page.split.image2.titre" />
                        <img :src="content.page.split.image3.url" :alt="content.page.split.image3.titre" />
                    </div>
                </div>
                <div class="imgs imgs-right">
                    <div class="scroller scroller-right">
                        <img :src="content.page.split.image4.url" :alt="content.page.split.image4.titre" />
                        <img :src="content.page.split.image5.url" :alt="content.page.split.image5.titre" />
                        <img :src="content.page.split.image6.url" :alt="content.page.split.image6.titre" />

                        <img :src="content.page.split.image4.url" :alt="content.page.split.image4.titre" />
                        <img :src="content.page.split.image5.url" :alt="content.page.split.image5.titre" />
                        <img :src="content.page.split.image6.url" :alt="content.page.split.image6.titre" />

                        <img :src="content.page.split.image4.url" :alt="content.page.split.image4.titre" />
                        <img :src="content.page.split.image5.url" :alt="content.page.split.image5.titre" />
                        <img :src="content.page.split.image6.url" :alt="content.page.split.image6.titre" />

                        <img :src="content.page.split.image4.url" :alt="content.page.split.image4.titre" />
                        <img :src="content.page.split.image5.url" :alt="content.page.split.image5.titre" />
                        <img :src="content.page.split.image6.url" :alt="content.page.split.image6.titre" />
                    </div>
                </div>
            </div>
        </section>

        <!-- Events -->
        <EvenementsListing v-if="pageIsLoaded" />

        <!-- Instagram Grid -->
        <section class="instagram-grid site-max-width" v-if="pageIsLoaded">
            <div class="top" data-inview="fadeInUp" data-delay="400">
                <h3 class="small-title">
                    {{ content.page.nomInstagram }}
                </h3>
                <a :href="content.page.lienInstagram" class="cta">
                    {{ $t('Suivez-nous') }}
                    <svg
                        width="50"
                        height="50"
                        viewBox="0 0 50 50"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                    >
                        <path
                            fill-rule="evenodd"
                            clip-rule="evenodd"
                            d="M25 0C11.1929 0 0 11.1929 0 25C0 38.8071 11.1929 50 25 50C38.8071 50 50 38.8071 50 25C50 11.1929 38.8071 0 25 0ZM19.5033 11.7471C20.9256 11.6824 21.38 11.6666 25.0012 11.6666H24.997C28.6193 11.6666 29.072 11.6824 30.4943 11.7471C31.9137 11.8121 32.8832 12.0369 33.7332 12.3666C34.611 12.7069 35.3527 13.1624 36.0943 13.9041C36.836 14.6452 37.2916 15.3891 37.6332 16.2661C37.961 17.1139 38.186 18.0828 38.2527 19.5022C38.3166 20.9244 38.3332 21.3789 38.3332 25C38.3332 28.6212 38.3166 29.0745 38.2527 30.4968C38.186 31.9157 37.961 32.8848 37.6332 33.7329C37.2916 34.6096 36.836 35.3535 36.0943 36.0946C35.3535 36.8363 34.6107 37.2929 33.734 37.6335C32.8857 37.9632 31.9157 38.188 30.4962 38.253C29.074 38.3177 28.6209 38.3335 24.9995 38.3335C21.3786 38.3335 20.9244 38.3177 19.5022 38.253C18.083 38.188 17.1139 37.9632 16.2655 37.6335C15.3891 37.2929 14.6452 36.8363 13.9044 36.0946C13.163 35.3535 12.7074 34.6096 12.3666 33.7326C12.0371 32.8848 11.8124 31.916 11.7471 30.4965C11.6827 29.0743 11.6666 28.6212 11.6666 25C11.6666 21.3789 11.6832 20.9242 11.7469 19.5019C11.8107 18.083 12.0357 17.1139 12.3663 16.2658C12.708 15.3891 13.1635 14.6452 13.9052 13.9041C14.6463 13.1627 15.3902 12.7071 16.2672 12.3666C17.115 12.0369 18.0839 11.8121 19.5033 11.7471Z"
                            fill="#212121"
                        />
                        <path
                            fill-rule="evenodd"
                            clip-rule="evenodd"
                            d="M23.8049 14.0693C24.0371 14.0689 24.287 14.069 24.5566 14.0691L25.001 14.0693C28.561 14.0693 28.983 14.082 30.3888 14.1459C31.6888 14.2054 32.3944 14.4226 32.8644 14.6051C33.4866 14.8468 33.9302 15.1357 34.3966 15.6023C34.8633 16.069 35.1522 16.5134 35.3944 17.1357C35.5769 17.6051 35.7944 18.3107 35.8536 19.6107C35.9175 21.0163 35.9314 21.4385 35.9314 24.9969C35.9314 28.5552 35.9175 28.9775 35.8536 30.383C35.7941 31.683 35.5769 32.3886 35.3944 32.8581C35.1528 33.4803 34.8633 33.9233 34.3966 34.3897C33.93 34.8564 33.4869 35.1453 32.8644 35.387C32.3949 35.5703 31.6888 35.787 30.3888 35.8464C28.9832 35.9103 28.561 35.9242 25.001 35.9242C21.4407 35.9242 21.0187 35.9103 19.6131 35.8464C18.3131 35.7864 17.6076 35.5692 17.1373 35.3867C16.5151 35.145 16.0706 34.8561 15.6039 34.3895C15.1373 33.9228 14.8484 33.4795 14.6062 32.8569C14.4236 32.3875 14.2061 31.6819 14.147 30.3819C14.0831 28.9764 14.0703 28.5541 14.0703 24.9935C14.0703 21.4329 14.0831 21.0129 14.147 19.6074C14.2064 18.3074 14.4236 17.6018 14.6062 17.1318C14.8478 16.5096 15.1373 16.0651 15.6039 15.5984C16.0706 15.1318 16.5151 14.8429 17.1373 14.6006C17.6073 14.4173 18.3131 14.2006 19.6131 14.1409C20.8432 14.0854 21.3198 14.0687 23.8049 14.0659V14.0693ZM32.1183 16.2833C31.2349 16.2833 30.5182 16.9991 30.5182 17.8827C30.5182 18.7661 31.2349 19.4827 32.1183 19.4827C33.0016 19.4827 33.7183 18.7661 33.7183 17.8827C33.7183 16.9994 33.0016 16.2827 32.1183 16.2827V16.2833ZM18.1536 25.0002C18.1536 21.2189 21.2193 18.1531 25.0006 18.1529C28.782 18.1529 31.8471 21.2188 31.8471 25.0002C31.8471 28.7816 28.7823 31.8461 25.0009 31.8461C21.2194 31.8461 18.1536 28.7816 18.1536 25.0002Z"
                            fill="#212121"
                        />
                        <path
                            d="M25.0011 20.5557C27.4556 20.5557 29.4456 22.5454 29.4456 25.0002C29.4456 27.4546 27.4556 29.4446 25.0011 29.4446C22.5464 29.4446 20.5566 27.4546 20.5566 25.0002C20.5566 22.5454 22.5464 20.5557 25.0011 20.5557Z"
                            fill="#212121"
                        />
                    </svg>
                </a>
            </div>
            <div class="grid-items">
                <a
                    :href="item.lien"
                    class="grid-item"
                    data-inview-off="revealLeft"
                    data-alternate-color
                    data-delay="200"
                    v-for="(item, i) in content.page.instagram"
                    :key="i"
                >
                    <div class="img-wrap">
                        <img :src="item.image.url" :alt="item.image.titre" />
                    </div>
                </a>
            </div>
        </section>
    </div>
</template>

<script>
// @ is an alias to /src
import { gsap } from 'gsap'
import { ScrollTrigger } from 'gsap/ScrollTrigger'
import { mapState, mapGetters } from 'vuex'
import { initInViewAnimations } from '@/plugins/InViewAnimations'
import EvenementsListing from '@/components/EvenementsListing'

gsap.registerPlugin(ScrollTrigger)

export default {
    name: 'Home',

    components: {
        EvenementsListing,
    },

    watch: {
        pageIsLoaded: {
            handler(pageIsLoaded) {
                if (pageIsLoaded) {
                    // Page loaded with Data
                    setTimeout(() => {
                        gsap.utils.toArray('.hero-slide').forEach(slide => {
                            ScrollTrigger.create({
                                trigger: slide,
                                start: 'top top',
                                pin: true,
                                pinSpacing: false,
                            })
                        })

                        gsap.utils.toArray('.title-down').forEach(title => {
                            gsap.to(title, {
                                scrollTrigger: {
                                    trigger: title,
                                    start: 'top bottom',
                                    end: 'bottom top',
                                    scrub: 1.475,
                                },
                                y: '-28%',
                                ease: 'none',
                            })
                        })

                        gsap.utils.toArray('.img-parallax-top').forEach(img => {
                            gsap.to(img, {
                                scrollTrigger: {
                                    trigger: img,
                                    start: 'top bottom',
                                    end: 'bottom top',
                                    scrub: 1.475,
                                },
                                y: '35%',
                                ease: 'none',
                            })
                        })

                        gsap.utils.toArray('.img-parallax-left').forEach(img => {
                            gsap.to(img, {
                                scrollTrigger: {
                                    trigger: img,
                                    start: 'top bottom',
                                    end: 'bottom top',
                                    scrub: 1.475,
                                },
                                x: '0%',
                                ease: 'none',
                            })
                        })

                        gsap.to('.scroller-left', {
                            duration: 300,
                            delay: 0,
                            y: '-85%',
                            ease: 'linear',
                        })

                        gsap.to('.scroller-right', {
                            duration: 300,
                            delay: 0,
                            y: '85%',
                            ease: 'linear',
                        })

                        initInViewAnimations()
                    }, 745)
                }
            },
            immediate: true,
        },
    },

    computed: {
        pageIsLoaded() {
            return this.contentIsLoaded && this.globalsIsLoaded
        },
        ...mapState(['contentIsLoaded', 'globalsIsLoaded']),
        ...mapGetters(['seo', 'content', 'globals']),
    },
}
</script>
